.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.link {
  stroke: #8ab4f8 !important;
  text-decoration: underline;
}

.main-title {
  font-family: 'SettaScript', Arial, sans-serif;
  margin-top: 10px;
  text-align: center;
}

.bold-title {
  font-weight: 600;
}

.sub-title {
  font-family: 'SettaScript', Arial, sans-serif;
  margin-top: 10px;
  margin-bottom: 5px;
}

.rsvp {
  padding: 20px;
  border: thin solid;
  font-family: 'LolaSansBold';
  display: flex;
  flex-direction: column;
  font-size: 20px;
  border-radius: 10px;
  max-width: 700px;
}

.rsvp button {
  font-family: 'LolaSansBold';
  font-size: 20px;
  border-radius: 5px;
  margin: 10px 5px 5px;
}

.rsvp button:hover {
  cursor: pointer;
}

.rsvp .margin {
  margin-top: 10px;
}

.rsvp input {
  font-family: 'LolaSansBold';
  padding: 5px;
  font-size: 18px;
  border-radius: 10px;
}

.rsvp fieldset {
  border-radius: 10px;
}

.text {
  font-family: "LolaSansBold";
  font-size: 22px;
  text-align: center;
  max-width: 700px;
}

li {
  list-style-position: inside;
  padding: 4px;
}

.deco1 {
  position: absolute;
  z-index: -1;
  width: 30%;
  left: 5%;
  top: 500px;
  min-width: 300px;
}

.deco2 {
  position: absolute;
  z-index: -1;
  width: 20%;
  right: 5%;
  top: 900px;
  min-width: 200px;
}

.deco3 {
  position: absolute;
  z-index: -1;
  width: 10%;
  left: 5%;
  bottom: 5%;
  min-width: 200px;
}

.foot {
  width: 20%;
  margin-top: 40px;
}

@media screen and (max-width: 800px)  {
  .main-title {
    font-size: 110px;
  }

  .sub-title {
    font-size: 100px;
  }

  main {
    width: 85%;
  }

  .rsvp {
    width: 90%;
  }

  .deco {
    opacity: 0.5;
  }
}

@media screen and (min-width: 800px) and (max-width: 1600px) {
  .main-title {
    font-size: 130px;
  }

  .sub-title {
    font-size: 120px;
  }

  .main {
    width: 75%;
  }

  .rsvp {
    width: 80%;
  }

  .deco {
    opacity: 0.75;
  }
}

@media screen and (min-width: 1600px) {
  .main-title {
    font-size: 150px;
  }

  .sub-title {
    font-size: 100px;
  }

  .main {
    width: 60%;
    max-width: 1200px;
  }

  .rsvp {
    width: 70%;
  }
}