@font-face {
  font-family: 'SettaScript';
  src: url('fonts/setta_script-webfont.woff2') format('woff2'),
  url('fonts/setta_script-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LolaSansBold';
  src: url('fonts/lolasans-bold.woff2') format('woff2'),
  url('fonts/lolasans-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProvidenceSans';
  src: url('fonts/providence_sans_regular-webfont.woff2') format('woff2'),
  url('fonts/providence_sans_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}
